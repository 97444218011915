import './styles.css';

export const WriteIconButton = () => {
  return (
    <div className="toolbar-btn-wrapper write-btn">
      <svg
        className="write-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 21"
        fill="none">
        <path
          d="M6.212 2.76063C6.5243 1.96878 7.64497 1.96879 7.95727 2.76064L8.92506 5.21451C9.02041 5.45627 9.21178 5.64764 9.45354 5.74299L11.9074 6.71078C12.6993 7.02308 12.6993 8.14375 11.9074 8.45605L9.45354 9.42384C9.21178 9.51919 9.02041 9.71056 8.92506 9.95231L7.95727 12.4062C7.64497 13.198 6.5243 13.198 6.212 12.4062L5.24421 9.95231C5.14886 9.71056 4.95749 9.51919 4.71573 9.42384L2.26186 8.45605C1.47001 8.14375 1.47001 7.02308 2.26186 6.71078L4.71574 5.74299C4.95749 5.64764 5.14886 5.45627 5.24421 5.21451L6.212 2.76063Z"
          fill="#6A6B76"
        />
        <path
          d="M14.1025 11.6596C14.2741 11.2245 14.89 11.2245 15.0616 11.6596L15.8122 13.5627C15.8646 13.6956 15.9697 13.8007 16.1026 13.8531L18.0057 14.6037C18.4408 14.7753 18.4408 15.3912 18.0057 15.5628L16.1026 16.3134C15.9697 16.3658 15.8646 16.4709 15.8122 16.6038L15.0616 18.5069C14.89 18.942 14.2741 18.942 14.1025 18.5069L13.3519 16.6038C13.2995 16.4709 13.1943 16.3658 13.0615 16.3134L11.1584 15.5628C10.7232 15.3912 10.7232 14.7753 11.1584 14.6037L13.0615 13.8531C13.1943 13.8007 13.2995 13.6956 13.3519 13.5627L14.1025 11.6596Z"
          fill="#6A6B76"
        />
      </svg>
      <span className="toolbar-btn-text">Write</span>
    </div>
  );
};
