import './styles.css';

export const PenEditIcon = () => {
  return (
    <svg
      className="btn-preview-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none">
      <path
        d="M11.9661 3.40018L12.7385 2.62778C14.0182 1.34803 16.0931 1.34803 17.3729 2.62778C18.6526 3.90754 18.6526 5.98243 17.3729 7.26218L16.6005 8.03458M11.9661 3.40018C11.9661 3.40018 12.0626 5.04153 13.5109 6.48978C14.9591 7.93803 16.6005 8.03458 16.6005 8.03458M11.9661 3.40018L4.86504 10.5012C4.38407 10.9822 4.14358 11.2227 3.93676 11.4878C3.69279 11.8006 3.48363 12.1391 3.31297 12.4972C3.16829 12.8007 3.06075 13.1234 2.84565 13.7687L1.93419 16.503M16.6005 8.03458L9.49943 15.1356C9.01846 15.6166 8.77798 15.8571 8.51282 16.0639C8.20003 16.3079 7.86159 16.517 7.5035 16.6877C7.19993 16.8324 6.87728 16.9399 6.232 17.155L3.49762 18.0665M3.49762 18.0665L2.82922 18.2893C2.51167 18.3951 2.16156 18.3125 1.92487 18.0758C1.68819 17.8391 1.60554 17.489 1.71139 17.1714L1.93419 16.503M3.49762 18.0665L1.93419 16.503"
        stroke="#2A2B3B"
        strokeWidth="1.5"
      />
      <defs>
        <clipPath id="clip0_10125_53149">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
