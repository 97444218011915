import './styles.css';

export const ImageUploaderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className="dropzone-placeholder-icon">
      <path
        d="M29.9997 13.332C29.9997 15.173 28.5073 16.6654 26.6663 16.6654C24.8254 16.6654 23.333 15.173 23.333 13.332C23.333 11.4911 24.8254 9.9987 26.6663 9.9987C28.5073 9.9987 29.9997 11.4911 29.9997 13.332Z"
        fill="#C0C0C5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0953 2.08203H19.904C16.0567 2.08201 13.0417 2.08199 10.6892 2.39828C8.28129 2.72201 6.38125 3.39758 4.8899 4.88893C3.39855 6.38028 2.72299 8.28031 2.39925 10.6882C2.08297 13.0407 2.08299 16.0557 2.08301 19.903V20.0943C2.08299 23.9416 2.08297 26.9567 2.39925 29.3092C2.72299 31.7171 3.39855 33.6171 4.8899 35.1085C6.38125 36.5998 8.28129 37.2754 10.6892 37.5991C13.0417 37.9154 16.0567 37.9154 19.9041 37.9154H20.0953C23.9426 37.9154 26.9577 37.9154 29.3102 37.5991C31.7181 37.2754 33.6181 36.5998 35.1095 35.1085C36.6008 33.6171 37.2764 31.7171 37.6001 29.3092C37.9164 26.9567 37.9164 23.9416 37.9163 20.0943V19.9031C37.9164 16.0558 37.9164 13.0407 37.6001 10.6882C37.2764 8.28031 36.6008 6.38028 35.1095 4.88893C33.6181 3.39758 31.7181 2.72201 29.3102 2.39828C26.9577 2.08199 23.9426 2.08201 20.0953 2.08203ZM6.65767 6.65669C7.6071 5.70727 8.89163 5.16244 11.0223 4.87598C13.1889 4.58469 16.036 4.58203 19.9997 4.58203C23.9634 4.58203 26.8104 4.58469 28.977 4.87598C31.1077 5.16244 32.3923 5.70727 33.3417 6.65669C34.2911 7.60612 34.8359 8.89066 35.1224 11.0213C35.4137 13.188 35.4163 16.035 35.4163 19.9987C35.4163 20.7491 35.4162 21.4594 35.4141 22.1327L35.0439 22.0815C30.3044 21.4252 25.9672 23.8892 23.7586 27.603C20.9095 20.3947 13.3804 15.4788 4.96756 16.6868L4.59129 16.7411C4.61022 14.3986 4.67247 12.5423 4.87696 11.0213C5.16342 8.89066 5.70824 7.60612 6.65767 6.65669Z"
        fill="#C0C0C5"
      />
    </svg>
  );
};
