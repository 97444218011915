import './styles.css';

export const ImageIcon = () => {
  return (
    <>
      <svg
        className="building-icon image-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="81"
        viewBox="0 0 80 81"
        fill="none">
        <path
          d="M50.5942 32.1406C49.891 31.1094 48.7348 30.5 47.5004 30.5C46.266 30.5 45.0942 31.1094 44.4067 32.1406L30.8129 52.0781L26.6723 46.9062C25.9535 46.0156 24.8754 45.5 23.7504 45.5C22.6254 45.5 21.5317 46.0156 20.8285 46.9062L10.8285 59.4062C9.92229 60.5312 9.75041 62.0781 10.3754 63.375C11.0004 64.6719 12.3129 65.5 13.7504 65.5H66.2504C67.641 65.5 68.9223 64.7344 69.5629 63.5C70.2035 62.2656 70.1254 60.7812 69.3442 59.6406L50.5942 32.1406Z"
          fill="#AAAAB1"
        />
        <path
          d="M17.5 30.5C19.4891 30.5 21.3968 29.7098 22.8033 28.3033C24.2098 26.8968 25 24.9891 25 23C25 21.0109 24.2098 19.1032 22.8033 17.6967C21.3968 16.2902 19.4891 15.5 17.5 15.5C15.5109 15.5 13.6032 16.2902 12.1967 17.6967C10.7902 19.1032 10 21.0109 10 23C10 24.9891 10.7902 26.8968 12.1967 28.3033C13.6032 29.7098 15.5109 30.5 17.5 30.5Z"
          fill="#D4D5D8"
        />
      </svg>
      <p className="building-text">Image</p>
    </>
  );
};
