import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PreviewIcon } from './PreviewIcon';
import { DeleteIcon } from './DeleteIcon';
import { DinoUploader } from '../../../../components/drag-n-drop-uploader/DinoUploader';
import { DinoLabel } from '../../../../components/inputComponent/DinoLabel';
import { updateField } from '../../../../store/campaignSlice';
import './style.css';

export const UploadHtmlForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { html } = useSelector((state) => state.campaign.data);

  const uploadOptionIcon = () => {
    return (
      <>
        {!!html && (
          <span>
            <PreviewIcon
              className={'campaign-preview-icon'}
              onClick={() => navigate('/campaigns/create/html/preview')}
            />
            <DeleteIcon
              className={`campaign-delete-icon`}
              onClick={() => dispatch(updateField({ field: 'html', value: '' }))}
            />
          </span>
        )}
      </>
    );
  };

  return (
    <div className="campaign-upload_html-form">
      <div className="dino-input-icon-block">
        <DinoLabel label="Email" required={true} labelInfo={uploadOptionIcon()} />
      </div>
      <DinoUploader />
    </div>
  );
};
