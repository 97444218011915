import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { DinoLogoIcon } from '../../../../components/mail-block-icons/DinoLogoIcon';
import { DinoLogoText } from '../../../../components/mail-block-icons/DinoLogoText';
import '../styles.css';
import { DeleteStickyIcon } from '../../../../components/interface/Buttons/DeleteStickyIcon';

export const FooterBuildBlock = ({
  id,
  text,
  style,
  isActive,
  onClick,
  showMailPreview,
  onClickRemoveBuildBlock,
}) => {
  let isActiveBorderOnHover = !showMailPreview ? ' is-hover-block' : '';
  let isActiveBorderOnClick = isActive && !showMailPreview ? ' is-active-content-border' : '';

  return (
    <>
      <div
        id={id}
        className={`tag-content${isActiveBorderOnHover}${isActiveBorderOnClick} footer-build-block`}
        style={style}
        onClick={onClick}>
        <Link
          className="unsubscribe-link"
          to="#"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(text),
          }}
        />
        <div className="logos-wrapper">
          <span className="powered_by">Powered by</span>
          <div className="logos-container">
            <DinoLogoIcon />
            <DinoLogoText />
          </div>
        </div>
      </div>
      <DeleteStickyIcon
        showMailPreview={showMailPreview}
        isActive={isActive}
        onClick={onClickRemoveBuildBlock}
      />
    </>
  );
};
