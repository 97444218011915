export const DinoLogoText = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="18" viewBox="0 0 36 8" fill="none">
      <path
        d="M2.62852 0.940142C3.48697 0.940142 4.20235 1.2412 4.77466 1.84331C5.35292 2.44542 5.64205 3.18762 5.64205 4.06992C5.64205 4.95223 5.35292 5.69443 4.77466 6.29654C4.20235 6.89865 3.48697 7.19971 2.62852 7.19971H0.142578V0.940142H2.62852ZM2.62852 6.01933C3.17102 6.01933 3.61217 5.84049 3.95197 5.4828C4.29178 5.11915 4.46168 4.64819 4.46168 4.06992C4.46168 3.49166 4.29178 3.02368 3.95197 2.66599C3.61217 2.30234 3.17102 2.12052 2.62852 2.12052H1.37661V6.01933H2.62852Z"
        fill="#95959D"
      />
      <path
        d="M7.03606 2.19206C6.8453 2.19206 6.67837 2.1235 6.5353 1.98638C6.39818 1.84331 6.32963 1.67639 6.32963 1.48562C6.32963 1.29485 6.39818 1.12793 6.5353 0.984853C6.67837 0.841777 6.8453 0.770239 7.03606 0.770239C7.23279 0.770239 7.39971 0.841777 7.53683 0.984853C7.6799 1.12793 7.75144 1.29485 7.75144 1.48562C7.75144 1.67639 7.6799 1.84331 7.53683 1.98638C7.39971 2.1235 7.23279 2.19206 7.03606 2.19206ZM6.46376 7.19971V2.72859H7.61731V7.19971H6.46376Z"
        fill="#95959D"
      />
      <path
        d="M11.1591 2.6034C11.6479 2.6034 12.0503 2.76734 12.3663 3.09522C12.6882 3.4231 12.8491 3.87618 12.8491 4.45444V7.19971H11.6956V4.59752C11.6956 4.29944 11.6151 4.07291 11.4542 3.91791C11.2932 3.75695 11.0786 3.67647 10.8103 3.67647C10.5122 3.67647 10.2738 3.76887 10.0949 3.95368C9.91609 4.13848 9.82667 4.41569 9.82667 4.7853V7.19971H8.67312V2.72859H9.82667V3.22935C10.1069 2.81205 10.551 2.6034 11.1591 2.6034Z"
        fill="#95959D"
      />
      <path
        d="M17.6435 6.64529C17.1845 7.09836 16.6241 7.3249 15.9624 7.3249C15.3006 7.3249 14.7403 7.09836 14.2812 6.64529C13.8282 6.18625 13.6016 5.62587 13.6016 4.96415C13.6016 4.30242 13.8282 3.74502 14.2812 3.29195C14.7403 2.83292 15.3006 2.6034 15.9624 2.6034C16.6241 2.6034 17.1845 2.83292 17.6435 3.29195C18.1025 3.74502 18.3321 4.30242 18.3321 4.96415C18.3321 5.62587 18.1025 6.18625 17.6435 6.64529ZM15.095 5.84943C15.3275 6.08193 15.6166 6.19818 15.9624 6.19818C16.3081 6.19818 16.5973 6.08193 16.8298 5.84943C17.0623 5.61693 17.1785 5.32184 17.1785 4.96415C17.1785 4.60646 17.0623 4.31137 16.8298 4.07887C16.5973 3.84637 16.3081 3.73012 15.9624 3.73012C15.6166 3.73012 15.3275 3.84637 15.095 4.07887C14.8684 4.31137 14.7552 4.60646 14.7552 4.96415C14.7552 5.32184 14.8684 5.61693 15.095 5.84943Z"
        fill="#95959D"
      />
      <path
        d="M24.0886 2.6034C24.6013 2.6034 25.0097 2.77032 25.3137 3.10416C25.6237 3.43801 25.7787 3.88512 25.7787 4.4455V7.19971H24.6252V4.52598C24.6252 4.25771 24.5596 4.04906 24.4284 3.90002C24.2973 3.75098 24.1125 3.67647 23.874 3.67647C23.6117 3.67647 23.406 3.76291 23.257 3.93579C23.1139 4.10867 23.0424 4.35906 23.0424 4.68694V7.19971H21.8888V4.52598C21.8888 4.25771 21.8233 4.04906 21.6921 3.90002C21.561 3.75098 21.3761 3.67647 21.1377 3.67647C20.8813 3.67647 20.6757 3.76291 20.5207 3.93579C20.3716 4.10867 20.2971 4.35906 20.2971 4.68694V7.19971H19.1436V2.72859H20.2971V3.20253C20.5654 2.80311 20.9797 2.6034 21.5401 2.6034C22.0885 2.6034 22.4939 2.81801 22.7562 3.24724C23.0543 2.81801 23.4984 2.6034 24.0886 2.6034Z"
        fill="#95959D"
      />
      <path
        d="M30.1565 2.72859H31.31V7.19971H30.1565V6.67212C29.8107 7.1073 29.3249 7.3249 28.6989 7.3249C28.1028 7.3249 27.5901 7.09836 27.1608 6.64529C26.7376 6.18625 26.5259 5.62587 26.5259 4.96415C26.5259 4.30242 26.7376 3.74502 27.1608 3.29195C27.5901 2.83292 28.1028 2.6034 28.6989 2.6034C29.3249 2.6034 29.8107 2.82099 30.1565 3.25618V2.72859ZM28.0282 5.87626C28.2607 6.10875 28.5558 6.225 28.9135 6.225C29.2712 6.225 29.5663 6.10875 29.7988 5.87626C30.0373 5.6378 30.1565 5.33376 30.1565 4.96415C30.1565 4.59454 30.0373 4.29348 29.7988 4.06098C29.5663 3.82252 29.2712 3.70329 28.9135 3.70329C28.5558 3.70329 28.2607 3.82252 28.0282 4.06098C27.7957 4.29348 27.6795 4.59454 27.6795 4.96415C27.6795 5.33376 27.7957 5.6378 28.0282 5.87626Z"
        fill="#95959D"
      />
      <path
        d="M32.9284 2.19206C32.7376 2.19206 32.5707 2.1235 32.4276 1.98638C32.2905 1.84331 32.2219 1.67639 32.2219 1.48562C32.2219 1.29485 32.2905 1.12793 32.4276 0.984853C32.5707 0.841777 32.7376 0.770239 32.9284 0.770239C33.1251 0.770239 33.292 0.841777 33.4291 0.984853C33.5722 1.12793 33.6438 1.29485 33.6438 1.48562C33.6438 1.67639 33.5722 1.84331 33.4291 1.98638C33.292 2.1235 33.1251 2.19206 32.9284 2.19206ZM32.3561 7.19971V2.72859H33.5096V7.19971H32.3561Z"
        fill="#95959D"
      />
      <path d="M34.5654 7.19971V0.671875H35.719V7.19971H34.5654Z" fill="#95959D" />
    </svg>
  );
};
