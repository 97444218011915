import './styles.css';

export const GiphyIcon = () => {
  return (
    <>
      <svg
        className="building-icon giphy-icon"
        width="77"
        height="18"
        viewBox="0 0 77 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.9712 3.32L11.8432 6.872C11.1552 6.072 10.2352 5.672 9.08322 5.672C8.05922 5.672 7.25922 5.992 6.68322 6.632C6.10722 7.256 5.81922 8.032 5.81922 8.96C5.81922 9.92 6.12322 10.72 6.73122 11.36C7.33922 11.984 8.11522 12.296 9.05922 12.296C9.73122 12.296 10.3072 12.16 10.7872 11.888C11.2832 11.6 11.5312 11.304 11.5312 11H8.79522V7.208H17.2432V9.152C17.2432 11.648 16.4672 13.632 14.9152 15.104C13.3632 16.56 11.3872 17.288 8.98722 17.288C6.42722 17.288 4.33922 16.52 2.72322 14.984C1.10722 13.448 0.299219 11.44 0.299219 8.96C0.299219 6.528 1.11522 4.536 2.74722 2.984C4.37922 1.432 6.48322 0.655999 9.05922 0.655999C10.5312 0.655999 11.8192 0.863999 12.9232 1.28C14.0272 1.696 15.0432 2.376 15.9712 3.32ZM19.0398 17V0.943999H24.5838V17H19.0398ZM35.1212 0.943999C36.9452 0.943999 38.4412 1.52 39.6092 2.672C40.7932 3.824 41.3852 5.32 41.3852 7.16C41.3852 9.08 40.7852 10.616 39.5852 11.768C38.4012 12.904 36.7932 13.472 34.7612 13.472H32.4812V17H26.9852V0.943999H35.1212ZM32.4812 8.792H34.2812C34.7292 8.792 35.1052 8.648 35.4092 8.36C35.7132 8.056 35.8652 7.68 35.8652 7.232C35.8652 6.768 35.7132 6.384 35.4092 6.08C35.1212 5.776 34.7612 5.624 34.3292 5.624H32.4812V8.792ZM52.689 6.92V0.943999H58.281V17H52.689V12.008H48.585V17H42.993V0.943999H48.585V6.92H52.689ZM67.7437 6.632L69.9277 0.943999H76.3357L70.4797 12.656V17H64.9597V12.656L59.2477 0.943999H65.6557L67.7437 6.632Z"
          fill="#AAAAB1"
        />
      </svg>
      <p className="building-text">.GIF</p>
    </>
  );
};
