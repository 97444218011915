export const LinkedIn = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.40925 4.19922C5.79705 4.19922 5.30078 4.68045 5.30078 5.27373V18.1247C5.30078 18.7182 5.79705 19.1992 6.40925 19.1992H19.1923C19.8045 19.1992 20.3008 18.7182 20.3008 18.1247V5.27373C20.3008 4.68045 19.8045 4.19922 19.1923 4.19922H6.40925ZM9.84789 16.7556V9.98229H7.58227V16.7556H9.84789ZM8.71507 9.0575C9.50514 9.0575 9.99689 8.53739 9.99689 7.88739C9.98216 7.22276 9.50514 6.71708 8.73006 6.71708C7.95506 6.71708 7.44834 7.22276 7.44834 7.88739C7.44834 8.53739 7.93999 9.0575 8.7003 9.0575H8.71507ZM11.1016 16.7556H13.3672V12.9731C13.3672 12.7707 13.3819 12.5684 13.4417 12.4237C13.6055 12.0193 13.9783 11.6004 14.6042 11.6004C15.424 11.6004 15.7519 12.2215 15.7519 13.132V16.7556H18.0174V12.8719C18.0174 10.7914 16.8996 9.82335 15.409 9.82335C14.2072 9.82335 13.6683 10.4797 13.3672 10.9413V9.98229H11.1017C11.1314 10.6179 11.1016 16.7556 11.1016 16.7556ZM13.3672 10.9413V10.9646H13.3521C13.3571 10.9569 13.3621 10.9491 13.3672 10.9413Z"
        fill="#2A2B3B"
      />
    </svg>
  );
};
