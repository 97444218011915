import React, { useState } from 'react';
import './style.scss';
import DinoHeader from '../../../components/header/DinoHeader';
import analyticsIcon from '../../../assets/images/analytics-outline.svg';
import { CampaignsTab } from '../../../components/analyticsTabs/campaigns/CampaignsTab';
import { TestsTab } from '../../../components/analyticsTabs/tests/TestsTab';
import AnalyticsTabs from '../../../components/analyticsTabs/AnalyticsTabs';

export const Analytics = () => {
  const [activeTab, setActiveTab] = useState('campaigns');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'campaigns':
        return <CampaignsTab />;
      case 'tests':
        return <TestsTab />;
      default:
        return null;
    }
  };

  const renderHeaderContent = () => {
    return (
      <button className="compare-btn" disabled>
        <span>Compare</span>
      </button>
    );
  };

  return (
    <div className="analytics-wrapper">
      <DinoHeader icon={analyticsIcon} description={'Analytics'} content={renderHeaderContent()} />
      <div className="analytics-content">
        <AnalyticsTabs
          activeTab={activeTab}
          onTabClick={setActiveTab}
          tabContent={renderTabContent()}
        />
        <div className="tab-box">{activeTab === 'tests' ? <TestsTab /> : <CampaignsTab />}</div>
      </div>
    </div>
  );
};
