import './styles.css';

export const DragIcon = () => {
  return (
    <svg
      className="sticky-icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="3" fill="#7E9D00" />
      <path d="M5 6.66797H15" stroke="#FAFAFA" strokeLinecap="round" />
      <path d="M5 10H15" stroke="#FAFAFA" strokeLinecap="round" />
      <path d="M5 13.3359H15" stroke="#FAFAFA" strokeLinecap="round" />
    </svg>
  );
};
