import './styles.css';

export const HtmlIcon = () => {
  return (
    <>
      <svg
        className="building-icon html-icon"
        width="80"
        height="81"
        viewBox="0 0 80 81"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="0.2"
          d="M57.4303 25.648C56.176 24.3882 54.1423 24.3882 52.888 25.648C51.6337 26.9078 51.6337 28.9503 52.888 30.2101L53.6228 30.9481C56.542 33.88 58.4981 35.8544 59.7641 37.521C60.9737 39.1132 61.247 40.04 61.247 40.8327C61.247 41.6254 60.9737 42.5522 59.7641 44.1444C58.4981 45.811 56.542 47.7853 53.6228 50.7173L52.888 51.4553C51.6337 52.7151 51.6337 54.7576 52.888 56.0174C54.1423 57.2772 56.176 57.2772 57.4303 56.0174L58.3227 55.1211C61.0434 52.3886 63.3122 50.1101 64.8712 48.0579C66.5166 45.8918 67.6708 43.6032 67.6708 40.8327C67.6708 38.0621 66.5166 35.7736 64.8712 33.6075C63.3122 31.5553 61.0433 29.2766 58.3225 26.5441L57.4303 25.648Z"
          fill="#2A2B3B"
        />
        <path
          opacity="0.4"
          d="M48.8151 20.8937C49.2742 19.1728 48.2573 17.4039 46.5439 16.9428C44.8305 16.4816 43.0693 17.5029 42.6102 19.2238L31.5263 60.7704C31.0671 62.4913 32.084 64.2602 33.7974 64.7213C35.5108 65.1824 37.272 64.1612 37.7311 62.4402L48.8151 20.8937Z"
          fill="#2A2B3B"
        />
        <path
          opacity="0.2"
          d="M27.4547 25.648C26.2003 24.3882 24.1667 24.3882 22.9124 25.648L22.0201 26.5441C19.2993 29.2766 17.0304 31.5553 15.4715 33.6075C13.826 35.7736 12.6719 38.0621 12.6719 40.8327C12.6719 43.6032 13.826 45.8918 15.4715 48.0579C17.0304 50.1101 19.2993 52.3887 22.0201 55.1213L22.9124 56.0174C24.1667 57.2772 26.2003 57.2772 27.4547 56.0174C28.709 54.7576 28.709 52.7151 27.4547 51.4553L26.7199 50.7173C23.8007 47.7853 21.8446 45.811 20.5785 44.1444C19.369 42.5522 19.0956 41.6254 19.0956 40.8327C19.0956 40.04 19.369 39.1132 20.5785 37.521C21.8446 35.8544 23.8007 33.88 26.7199 30.9481L27.4547 30.2101C28.709 28.9503 28.709 26.9078 27.4547 25.648Z"
          fill="#2A2B3B"
        />
      </svg>
      <p className="building-text">HTML</p>
    </>
  );
};
