import { useState } from 'react';
import { Accordion } from '../../../../components/accordion/Accordion';
import uploadIcon from '../../../../assets/images/uploadIcon.svg';
import alternateTextIcon from '../../../../assets/images/alternateTextIcon.svg';
import { SearchIcon } from '../../../../components/interface/icons/SearchIcon';
import { DinoInput } from '../../../../components/inputComponent/DinoInput';
import '../styles.css';

export const ImageEditor = () => {
  const [searchImageUrl, setSearchImageUrl] = useState('');

  return (
    <div className="image-editor-container">
      <Accordion icon={uploadIcon} title="Upload Image">
        <DinoInput
          leftIcon={<SearchIcon />}
          // rightIcon={<CloseIcon onClick={() => {}} />}
          value={searchImageUrl}
          onChange={({ target: { value } }) => setSearchImageUrl(value)}
          placeholder="Enter url here"
        />
      </Accordion>
      <Accordion icon={alternateTextIcon} title="Alternate text">
        Its really hot inside Jupiter! No one knows exactly how hot, but scientists think it could
        be about 43,000°F (24,000°C) near Jupiters center, or core.
      </Accordion>
    </div>
  );
};
