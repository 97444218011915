import './styles.css';

export const VideoIcon = () => {
  return (
    <>
      <svg
        className="building-icon video-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="101"
        viewBox="0 0 100 101"
        fill="none">
        <path
          d="M52 39.3469L52 39.3341L52 39.3212V32.5H57.8649C58.5952 32.5 59.2881 32.5 59.946 32.5021L59.946 32.5156V42.1813H52V39.3469Z"
          fill="#AAAAB1"
        />
        <path
          d="M52 59.8468L52 59.8341L52 59.8213L52 45.5979L72.9957 45.5979L72.9999 45.5979L73 59.8333C73 60.8622 73 61.8089 72.991 62.6816L72.9564 62.6813H52V59.8468Z"
          fill="#AAAAB1"
        />
        <path
          d="M52 73.4282C52.1814 73.4751 52.3716 73.5 52.5676 73.5H57.8649C58.5952 73.5 59.2881 73.5 59.946 73.4979L59.946 73.4859L59.946 66.0979H52V73.4282Z"
          fill="#AAAAB1"
        />
        <path
          d="M63.3514 42.1813V32.553C67.0057 32.6777 69.2275 33.0964 70.7835 34.5014C72.4863 36.039 72.8809 38.2965 72.9724 42.1813L63.3514 42.1813Z"
          fill="#AAAAB1"
        />
        <path
          d="M72.8622 66.0979H63.3514V73.447C67.0058 73.3223 69.2275 72.9036 70.7835 71.4986C72.1219 70.29 72.6521 68.6367 72.8622 66.0979Z"
          fill="#AAAAB1"
        />
        <path
          d="M48 27.5718C47.8186 27.5249 47.6284 27.5 47.4324 27.5H42.1351C41.4049 27.5 40.712 27.5 40.0541 27.5021L40.0541 27.5156V37.1813L48 37.1813L48 27.5718Z"
          fill="#D4D5D8"
        />
        <path
          d="M48 40.5979L27.0044 40.5979L27.0001 40.5979L27 54.8333C27 55.8622 27 56.8089 27.009 57.6816L27.0438 57.6813H48V40.5979Z"
          fill="#D4D5D8"
        />
        <path
          d="M48 61.0979L40.0541 61.0979L40.0541 68.4859L40.0541 68.4979C40.712 68.5 41.4049 68.5 42.1351 68.5H48L48 61.0979Z"
          fill="#D4D5D8"
        />
        <path
          d="M36.6487 27.553C32.9943 27.6777 30.7725 28.0964 29.2165 29.5014C27.5137 31.039 27.1191 33.2965 27.0276 37.1813L36.6487 37.1813V27.553Z"
          fill="#D4D5D8"
        />
        <path
          d="M36.6487 61.0979L36.6487 68.447C32.9943 68.3223 30.7725 67.9036 29.2165 66.4986C27.8781 65.29 27.3479 63.6367 27.1378 61.0979L36.6487 61.0979Z"
          fill="#D4D5D8"
        />
      </svg>
      <p className="building-text">Video</p>
    </>
  );
};
