import './styles.css';

export const DeleteStickyIcon = ({ showMailPreview, isActive, onClick }) => {
  return (
    <>
      {showMailPreview ||
        (isActive && (
          <svg
            className={`is-active-icon-delete`}
            onClick={onClick}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="5" fill="#7E9D00" />
            <path
              d="M5.375 7.08334C5.375 6.85322 5.54769 6.66667 5.76071 6.66667L7.09283 6.66645C7.35751 6.65921 7.59101 6.47741 7.68107 6.20846C7.68344 6.20138 7.68616 6.19266 7.69593 6.16101L7.75333 5.97497C7.78845 5.86089 7.81905 5.76151 7.86187 5.67268C8.03104 5.32175 8.34404 5.07805 8.70573 5.01566C8.79729 4.99987 8.89424 4.99993 9.00553 5.00001H10.7446C10.8558 4.99993 10.9528 4.99987 11.0444 5.01566C11.406 5.07805 11.719 5.32175 11.8882 5.67268C11.931 5.76151 11.9616 5.86089 11.9968 5.97497L12.0542 6.16101C12.0639 6.19266 12.0666 6.20138 12.069 6.20846C12.1591 6.47741 12.4389 6.65943 12.7036 6.66667H13.9893C14.2023 6.66667 14.375 6.85322 14.375 7.08334C14.375 7.31345 14.2023 7.5 13.9893 7.5H5.76071C5.54769 7.5 5.375 7.31345 5.375 7.08334Z"
              fill="#FAFAFA"
            />
            <path
              d="M9.80338 14.9999H10.1968C11.5506 14.9999 12.2275 14.9999 12.6676 14.5683C13.1077 14.1367 13.1527 13.4287 13.2427 12.0128L13.3725 9.97252C13.4213 9.20424 13.4458 8.8201 13.225 8.57668C13.0042 8.33325 12.6314 8.33325 11.8857 8.33325H8.11453C7.36885 8.33325 6.99602 8.33325 6.77524 8.57668C6.55446 8.8201 6.57889 9.20424 6.62775 9.97252L6.7575 12.0128C6.84755 13.4287 6.89258 14.1367 7.33267 14.5683C7.77276 14.9999 8.44964 14.9999 9.80338 14.9999Z"
              fill="#FAFAFA"
            />
          </svg>
        ))}
    </>
  );
};
