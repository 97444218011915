import './styles.css';

export const FooterIcon = () => {
  return (
    <>
      <svg
        className="building-icon footer-icon"
        width="100"
        height="101"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M34.124 78.168C28.5661 78.168 25.7872 78.168 24.0606 76.3227C22.334 74.4773 22.334 71.5074 22.334 65.5674C22.334 59.6274 22.334 56.6574 24.0606 54.8121C25.7872 52.9668 28.5661 52.9668 34.124 52.9668L66.5466 52.9668C72.1044 52.9668 74.8834 52.9668 76.61 54.8121C78.3366 56.6574 78.3366 59.6274 78.3366 65.5674C78.3366 71.5074 78.3366 74.4773 76.61 76.3227C74.8834 78.168 72.1044 78.168 66.5466 78.168L34.124 78.168Z"
          fill="#AAAAB1"
        />
        <path
          d="M59.8538 47.3672C54.0458 47.3672 51.1418 47.3672 49.3375 45.5219C47.5332 43.6766 47.5332 40.7066 47.5332 34.7666C47.5332 28.8266 47.5332 25.8566 49.3375 24.0113C51.1418 22.166 54.0458 22.166 59.8538 22.166L66.0141 22.166C71.822 22.166 74.726 22.166 76.5303 24.0113C78.3346 25.8566 78.3346 28.8266 78.3346 34.7666C78.3346 40.7066 78.3346 43.6766 76.5303 45.5219C74.726 47.3672 71.822 47.3672 66.0141 47.3672L59.8538 47.3672Z"
          fill="#D4D5D8"
        />
        <path
          d="M32.1344 47.3672C29.0901 47.3672 27.568 47.3672 26.3673 46.8876C24.7664 46.2482 23.4945 45.0217 22.8313 43.4779C22.334 42.3201 22.334 40.8523 22.334 37.9167L22.334 31.6165C22.334 28.6809 22.334 27.2131 22.8313 26.0553C23.4945 24.5115 24.7664 23.285 26.3673 22.6456C27.568 22.166 29.0901 22.166 32.1344 22.166C35.1787 22.166 36.7009 22.166 37.9016 22.6456C39.5025 23.285 40.7744 24.5115 41.4376 26.0553C41.9349 27.2131 41.9349 28.6809 41.9349 31.6165L41.9349 37.9167C41.9349 40.8523 41.9349 42.3201 41.4376 43.4779C40.7744 45.0217 39.5025 46.2482 37.9016 46.8876C36.7009 47.3672 35.1787 47.3672 32.1344 47.3672Z"
          fill="#D4D5D8"
        />
      </svg>

      <p className="building-text">Footer</p>
    </>
  );
};
