import { buildingBlocksInitialState, layoutBlocksInitialState } from '../initial.constants';
import * as scriptApi from '../../../api/builder/script';
import * as contentApi from '../../../api/builder/email_contents';
import * as resourceManager from './resourceManager';
import { getAccessToken } from '../../../api/auth/auth';
import * as templateApi from '../../../api/builder/templates';
import { v4 as uuidv4 } from 'uuid';
import { RootHtml, MailEditorToHTML } from '../../../helpers/TypeResolverComponent';
import { renderToStaticMarkup } from 'react-dom/server';

export function initBlock(block) {
  if (block instanceof Array) {
    return block.map((_block) => initBlock(_block));
  }
  const allBlocks = [...buildingBlocksInitialState, ...layoutBlocksInitialState];
  const blockInfo = allBlocks.find((_block) => _block.type === block.type);
  if (!blockInfo) {
    return;
  }
  const newBlock = { ...blockInfo, id: block.id, params: block.params };

  if (blockInfo.type?.startsWith('layout')) {
    for (let i = 0; i < newBlock.params.child.length; i++) {
      if (newBlock.params.child[i].children.length) {
        newBlock.params.child[i].children[0] = initBlock(newBlock.params.child[i].children[0]);
      }
    }
  }

  return newBlock;
}

export function filterBlockProperties(block) {
  if (block instanceof Array) {
    return block.map((_block) => filterBlockProperties(_block));
  }
  const newBlock = {
    id: block?.id,
    type: block?.type,
    params: block?.params,
  };

  if (newBlock.type?.startsWith('layout')) {
    newBlock.params.child = newBlock.params.child.map((child) => {
      return {
        id: child.id,
        children: filterBlockProperties(child.children),
      };
    });
  }

  return block;
}

export function cloneBlock(block) {
  if (block.type.startsWith('layout')) {
    return {
      ...block,
      id: uuidv4(),
      params: {
        ...JSON.parse(JSON.stringify(block.params)),
        child: block.params.child.map((child) => ({
          ...child,
          id: uuidv4(),
          children: child.children.map(cloneBlock),
        })),
      },
    };
  } else {
    return {
      ...block,
      id: uuidv4(),
      params: JSON.parse(JSON.stringify(block.params)),
    };
  }
}

export function findBlockById(blocks, id) {
  if (!blocks || !Array.isArray(blocks)) return null;

  for (let block of blocks) {
    if (block.id === id) {
      return block;
    }
    if (block.type.startsWith('layout')) {
      for (let i = 0; i < block.params.child.length; i++) {
        if (block.params.child[i].children.length) {
          if (block.params.child[i].id === id) {
            return block.params.child[i].children[0];
          }
          const childBlock = findBlockById(block.params.child[i].children, id);
          if (childBlock) {
            return childBlock;
          }
        }
      }
    }
  }
  return null;
}

export function removeBlockById(blocks, id) {
  return blocks
    .filter((block) => block.id !== id)
    .map((block) => {
      if (block.type.startsWith('layout')) {
        return {
          ...block,
          params: {
            ...block.params,
            child: block.params.child.map((child) => ({
              ...child,
              children: child.children.filter((childBlock) => childBlock.id !== id),
            })),
          },
        };
      } else {
        return block;
      }
    });
}

export function updateBlockById(blocks, id, field, value) {
  return blocks.map((block) => {
    if (block.id === id) {
      return {
        ...block,
        params: {
          ...block.params,
          [field]: value,
        },
      };
    }
    if (block.type.startsWith('layout')) {
      return {
        ...block,
        params: {
          ...block.params,
          child: block.params.child.map((child) => {
            if (child.id === id) {
              return {
                ...child,
                children: value,
              };
            } else {
              return {
                ...child,
                children: updateBlockById(child.children, id, field, value),
              };
            }
          }),
        },
      };
    }
    return block;
  });
}

export function duplicateBlockById(blocks, id) {
  const blockIndex = blocks.findIndex((block) => block.id === id);
  const block = cloneBlock(blocks[blockIndex]);
  return [...blocks.slice(0, blockIndex + 1), block, ...blocks.slice(blockIndex + 1)];
}

export function buildFinalHTML(mailEditorState) {
  return renderToStaticMarkup(<RootHtml>{MailEditorToHTML({ mailEditorState })}</RootHtml>);
}
