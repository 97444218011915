import { Asterisk } from '../asterisk/Asterisk';
import './styles.css';
import { DinoLabel } from './DinoLabel';

export const DinoInput = ({
  label,
  value,
  name,
  errors,
  required,
  onChange,
  leftIcon,
  rightIcon,
  placeholder,
  className,
  style,
  bg,
}) => {
  return (
    <div className="dino-input-icon-block">
      <DinoLabel label={label} required={required} />
      <div className="dino-input-icon-wrapper">
        {leftIcon}
        {rightIcon}
        <input
          value={value}
          onChange={onChange}
          required={required}
          id="dino-field"
          type="text"
          autoComplete="off"
          style={style}
          className={`dino-input-styles${leftIcon ? ' is-active-icon' : ''}${bg ? ' input-bg-color' : ' default-input-bg-color'} ${errors?.[name] ? 'errors' : ''}${className}`}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};
