export const TwitterIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M12.501 21.4747C17.7332 21.4747 21.9747 17.2332 21.9747 12.001C21.9747 6.76886 17.7332 2.52734 12.501 2.52734C7.26886 2.52734 3.02734 6.76886 3.02734 12.001C3.02734 17.2332 7.26886 21.4747 12.501 21.4747Z"
        fill="#2A2B3B"
        stroke="#FAFAFA"
        strokeMiterlimit="10"
      />
      <path
        d="M6.66135 6.51172L11.1897 12.5664L6.63281 17.4891H7.65846L11.6481 13.1791L14.8714 17.4891H18.3615L13.5783 11.094L17.8198 6.51172H16.7942L13.1201 10.481L10.1514 6.51172H6.66135ZM8.16961 7.26713H9.77293L16.853 16.7337H15.2497L8.16961 7.26713Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};
