import './styles.css';

export const DuplicateIcon = () => {
  return (
    <svg
      className="sticky-icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="5" fill="#7E9D00" />
      <path
        d="M11.62 5H9.67292C8.7908 4.99999 8.09209 4.99999 7.54527 5.0738C6.98251 5.14977 6.52701 5.30982 6.1678 5.67048C5.80858 6.03114 5.64917 6.48846 5.57351 7.05348C5.49999 7.6025 5.49999 8.30401 5.5 9.18967V12.1084C5.5 12.8625 5.95998 13.5087 6.61358 13.7796C6.57994 13.3249 6.57997 12.6868 6.58 12.156L6.58 9.69879L6.58 9.65121C6.57996 9.01037 6.57993 8.45822 6.63914 8.01605C6.7026 7.54219 6.84569 7.08796 7.21265 6.71953C7.57961 6.3511 8.03202 6.20743 8.50399 6.14372C8.94439 6.08427 9.49433 6.0843 10.1326 6.08434L10.18 6.08434H11.62L11.6674 6.08434C12.3057 6.0843 12.8544 6.08427 13.2948 6.14372C13.0314 5.47389 12.3808 5 11.62 5Z"
        fill="#FAFAFA"
      />
      <path
        d="M7.30078 9.70016C7.30078 8.33706 7.30078 7.65551 7.72255 7.23205C8.14431 6.80859 8.82314 6.80859 10.1808 6.80859H11.6208C12.9784 6.80859 13.6572 6.80859 14.079 7.23205C14.5008 7.65551 14.5008 8.33706 14.5008 9.70016V12.1098C14.5008 13.4729 14.5008 14.1544 14.079 14.5779C13.6572 15.0014 12.9784 15.0014 11.6208 15.0014H10.1808C8.82314 15.0014 8.14431 15.0014 7.72255 14.5779C7.30078 14.1544 7.30078 13.4729 7.30078 12.1098V9.70016Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};
