export const FacebookIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9016 12.0016C20.9016 16.6408 17.1408 20.4016 12.5016 20.4016C7.86237 20.4016 4.10156 16.6408 4.10156 12.0016C4.10156 7.36237 7.86237 3.60156 12.5016 3.60156C17.1408 3.60156 20.9016 7.36237 20.9016 12.0016ZM13.3741 6.17998H15.2349H15.2358V8.18808C15.1292 8.1887 14.8123 8.18848 14.5222 8.18827C14.3866 8.18817 14.257 8.18808 14.1572 8.18808C14.1559 8.18808 14.1538 8.18804 14.151 8.18798C14.0729 8.18637 13.4595 8.17376 13.4595 8.78441C13.4635 9.04277 13.4595 9.93243 13.4595 9.93243H15.243L15.0985 11.9324H13.5012V17.8232H11.0811V11.971H9.76406V9.93813H11.0415C11.0415 9.73348 11.0422 9.5366 11.0428 9.34391C11.044 8.97057 11.0451 8.61296 11.0415 8.2451C11.0415 6.64442 12.2698 6.17998 13.3741 6.17998Z"
        fill="#2A2B3B"
      />
    </svg>
  );
};
