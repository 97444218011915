import './styles.css';

export const ButtonIcon = () => {
  return (
    <>
      <svg
        className="building-icon button-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="101"
        viewBox="0 0 100 101"
        fill="none">
        <rect x="12" y="37.0176" width="75.6719" height="23.1649" rx="8" fill="#AAAAB1" />
        <path
          d="M62.9528 53.9977L63.3063 54.3513L62.9528 53.9977C62.3915 54.559 62.3864 55.336 62.566 56.1823C62.7458 57.0294 63.1532 58.1351 63.6697 59.537L63.6855 59.5798L65.4571 64.3886L65.4714 64.4273C65.7248 65.1153 65.9345 65.6845 66.1582 66.0936C66.3901 66.5179 66.6975 66.8848 67.1997 66.9951C67.702 67.1054 68.1349 66.9012 68.5232 66.6131C68.8977 66.3354 69.3267 65.9064 69.8451 65.3879L69.8743 65.3588L70.582 64.651L73.7039 67.7729L73.7154 67.7844C73.8855 67.9545 74.0307 68.0997 74.1587 68.2114C74.293 68.3285 74.4352 68.4332 74.6066 68.5042C74.9967 68.6658 75.4351 68.6658 75.8252 68.5042C75.9967 68.4332 76.1389 68.3285 76.2731 68.2114C76.4012 68.0997 76.5463 67.9545 76.7164 67.7844L76.728 67.7729L76.7395 67.7614C76.9096 67.5913 77.0548 67.4461 77.1664 67.3181C77.2836 67.1838 77.3883 67.0416 77.4593 66.8702C77.6209 66.48 77.6209 66.0417 77.4593 65.6515C77.3883 65.4801 77.2836 65.3379 77.1664 65.2036C77.0548 65.0756 76.9096 64.9304 76.7394 64.7603L76.728 64.7488L73.6061 61.6269L74.3138 60.9192L74.343 60.89C74.8614 60.3716 75.2904 59.9426 75.5682 59.5682C75.8562 59.1798 76.0605 58.7469 75.9502 58.2447C75.8399 57.7425 75.473 57.435 75.0487 57.2031C74.6396 56.9795 74.0703 56.7697 73.3824 56.5163L73.3437 56.5021L68.5349 54.7304L68.4921 54.7146C67.0902 54.1981 65.9845 53.7908 65.1373 53.611C64.2911 53.4314 63.5141 53.4364 62.9528 53.9977Z"
          fill="#D4D5D8"
          stroke="#FAFAFA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <p className="building-text">Button</p>
    </>
  );
};
