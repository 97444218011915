import { createSlice } from '@reduxjs/toolkit';

const fileSlice = createSlice({
  name: 'file',
  initialState: {
    uploadedFile: null,
    isFileUploaded: false,
  },
  reducers: {
    setUploadedFile(state, action) {
      state.uploadedFile = action.payload;
      state.isFileUploaded = true;
    },
    clearUploadedFile(state) {
      state.uploadedFile = null;
      state.isFileUploaded = false;
    },
  },
});

export const { setUploadedFile, clearUploadedFile } = fileSlice.actions;
export default fileSlice.reducer;
